import 'flatpickr/dist/flatpickr.min.css';
import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import './../sdist/tailwind.css';

//import Swiper from 'swiper';
import _ from 'lodash';
import $ from 'jquery';
import './js/photoswipe.js';

import morph from '@alpinejs/morph';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import 'htmx.org';


import Countable from 'countable';

import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de";

import TomSelect from 'tom-select/dist/esm/tom-select';
import 'tom-select/dist/esm/plugins/remove_button/plugin';
import '../node_modules/tom-select/dist/css/tom-select.min.css';

import './quill_snow.css';
import Quill from 'quill';
import persist from '@alpinejs/persist'

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


Chart.register(ChartDataLabels);

window.Alpine = Alpine
Alpine.plugin(morph);
Alpine.plugin(collapse);
Alpine.plugin(persist);


const TOOLBAR_CONFIG = [
  //[{ header: ['1', '2', false] }],
  ['bold', 'italic', 'underline', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['clean']
];

const SIMPLE_TOOLBAR_CONFIG = [
  ['bold', 'italic', 'underline', 'link'],
  //[{ list: 'ordered' }, { list: 'bullet' }],
  ['clean']
];


Alpine.store('shortcuts', {
  part_1: true,
  part_2: null,

});


document.addEventListener('alpine:init', () => {


  Alpine.data('toggleExtraField', (initial, condition) => ({
    value: initial,
    isOpen() { return condition(this.value); },
    init() {
      let node = this.$el.querySelector('[x-model="value"]');
      if (node) {
        this.value = node.value || '';
      }
    },
  }));

  Alpine.data('toggleExtraFieldChecked', (initial, condition) => ({
    value: initial,
    isOpen() { return condition(this.value); },
    init() {
      if (Array.isArray(initial)) {
        this.value = [...this.$el.querySelectorAll('[x-model="value"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="value"]:checked');
        if (node) {
          this.value = node.value;
        }
      }
    },
  }));

  Alpine.data('toggleInnerExtraFieldChecked', (initial, condition) => ({
    innerValue: initial,
    isOpen() { return condition(this.innerValue); },
    init() {
      if (Array.isArray(initial)) {
        this.innerValue = [...this.$el.querySelectorAll('[x-model="innerValue"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="innerValue"]:checked');
        if (node) {
          this.innerValue = node.value;
        }
      }
    },
  }));

  Alpine.data('toggleExtraFieldSelected', (initial, condition) => ({
    value: initial,
    isOpen() { return condition(this.value); },
    init() {
      if (Array.isArray(initial)) {
        this.value = [...this.$el.querySelectorAll('[x-model="value"] option[selected]')].map(node => node.value);
      } else {
        console.log(this.$el);
        this.value = this.$el.querySelector('[x-model="value"] option[selected]').value;
      }
    },
  }));


  Alpine.data('multiselect', () => ({
    value: [],
    init() {
      let settings = {};
      new TomSelect(this.$el, settings);
    }
  }));

  Alpine.magic('tooltip', el => message => {
    let instance = tippy(el, { content: message, trigger: 'manual' })

    instance.show()

    setTimeout(() => {
      instance.hide()

      setTimeout(() => instance.destroy(), 150)
    }, 2000)
  })

  // Directive: x-tooltip
  Alpine.directive('tooltip', (el, { expression }) => {
    tippy(el, { content: expression })
  })


  window.CountingField = function (limit, direction, ressource) {
    return {
      limit: limit,
      direction: direction || 'down',
      ressource: ressource || 'all',
      counter: 0,
      count() { return (this.direction === 'down') ? this.limit - this.counter : this.counter },
      reached() { return (this.direction === 'down') ? this.counter === 0 : this.counter >= this.limit },
      init() {
        let node = this.$refs.field;
        if (node) {
          Countable.on(node, counter => { this.counter = counter[this.ressource] })
        }
      }
    }
  }

  let dates = flatpickr(".datepicker_date", {
    "locale": German,
    enableTime: false,
    dateFormat: "d.m.Y",
  });

  let times = flatpickr(".datepicker_time", {
    "locale": German,
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
  });

  function reloadFlatpickr() {
    if (dates) {
      dates.forEach(
        date => date.destroy()
      );
    }

    if (times) {
      times.forEach(
        time => time.destroy()
      );
    }

    dates = flatpickr(".datepicker_date", {
      "locale": German,
      enableTime: false,
      dateFormat: "Y-m-d",
    });
    times = flatpickr(".datepicker_time", {
      "locale": German,
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
    });
  }

  let renderFlatpicker = (el) => {
    let picker = flatpickr(el, {
      "locale": German,
      enableTime: true,
      dateFormat: "d.m.Y H:i",
    })
  }

  let renderDateFlatpicker = (el) => {
    let picker = flatpickr(el, {
      "locale": German,
      enableTime: false,
      dateFormat: "d.m.Y",
    })
  }


  let renderTomSelect = (el) => {
    let settings = {};
    new TomSelect(el, settings);
  }

  let renderCreateSelect = (el) => {
    let settings = {
      create: true,
      createOnBlur: true,
      sortField: 'text'
    };
    new TomSelect(el, settings);
  };

  document.querySelectorAll('.multi-select').forEach(renderTomSelect);
  document.querySelectorAll('.create-select').forEach(renderCreateSelect);

  window.reloadFlatpickr = reloadFlatpickr;
  window.renderTomSelect = renderTomSelect;
  window.renderCreateSelect = renderCreateSelect;
  window.renderFlatpicker = renderFlatpicker;
  window.renderDateFlatpicker = renderDateFlatpicker;


  Alpine.data('loadQuill', (initial) => ({
    init() {
      let quill = new Quill(this.$refs.quill, {
        placeholder: 'Hier Text eingeben ...',
        modules: {
          toolbar: SIMPLE_TOOLBAR_CONFIG
        },
        theme: 'snow'
      })
      quill.root.innerHTML = initial;
      this.$refs.textarea.value = initial;
      this.$refs.textarea.style.display = "none";

      quill.on('text-change', () => {
        this.$refs.textarea._x_model.set(quill.root.innerHTML);
      })
    }
  }));



  window.toggleFeedBackField = function () {
    return {
      value: '',
      na: false,
      rating: null,
      open: false,
      isOpen() { return this.value || this.open; },
      init() {
        let node = this.$el.querySelector('[x-model="value"]');
        if (node) {
          this.value = node.value;
        }

        let rating = this.$el.querySelector('[x-model="rating"]:checked');
        if (rating) {
          this.rating = rating.value;
        }

        let na = this.$el.querySelector('[x-model="na"]:checked');
        if (na) {
          this.na = na.value;
        }
      }
    }
  }

  window.toggleExtraFieldSelected = function (initial, condition) {
    return {
      value: initial,
      isOpen() { return condition(this.value); },
      init() {
        if (Array.isArray(initial)) {
          this.value = [...this.$el.querySelectorAll('[x-model="value"] option[selected]')].map(node => node.value);
        } else {
          this.value = this.$el.querySelector('[x-model="value"] option[selected]').value;
        }
      },
    }
  }



  window.TotalRating = function (initialRating) {
    // {rating: {'fgdfgg': {'rating': x, 'weight': x, 'exclude': true}, ...}}
    return {
      exclude() { return _.filter(_.values(this.rating), o => o.exclude); },
      rated() { return _.filter(_.values(this.rating), o => o.rating !== null && !o.exclude); },
      count() {
        return this.rated().length + this.exclude().length;
      },
      ratedCount() {
        return this.rated().length;
      },
      total() {
        let totalWeights = 1; //_.sumBy(this.rated(), o => o.weight);
        let total = _.sumBy(this.rated(), o => o.weight * o.rating) / totalWeights;
        return total / this.ratedCount() * 10;
      },
      update(newRating) {
        // {label: 'fgdfgg', rating: x}
        let ratingValue = parseInt(newRating.rating);

        if (newRating.label in this.rating) {
          if (isNaN(ratingValue)) {
            this.rating[newRating.label].rating = null;
          } else {
            this.rating[newRating.label].rating = ratingValue;
          }
          this.rating[newRating.label].exclude = newRating.exclude || false;
        }
      },
      ...initialRating
    }
  }


  window.InlineForms = function (params) {
    return {
      template: null,
      add() {
        let container = document.getElementById(this.container);
        container.insertAdjacentHTML('beforeend', this.template(this.TOTAL_FORMS));
        this.TOTAL_FORMS++;
      },
      loadTemplate(id) {
        let template = document.getElementById(id);
        this.template = (id) => template.innerHTML.replace(/__prefix__/g, id).replace(/__number__/g, id + 1);
      },
    }
  };

  Alpine.store('preview', {
    active: null,
    fullscreen: false,
    app: { id: '', rank: '', name: '' },
    pages: [],

    setData(pages, app) {
      this.pages = pages;
      this.app = app;
      this.active = '#app';
    },

    activeUrl() {
      const active_page = this.pages.find(page => page.hash === this.active);
      return active_page ? active_page.url : null;
    },

    // rating-preview
  });

  const preview = Alpine.store('preview')

  window.PreviewIframe = function () {
    return {
      init() {
        let that = this;
        Alpine.watch(
          'preview.active', () => that.$el.contentWindow.location.replace(preview.activeUrl())
        );
      }
    }
  }


  function CountableField(textarea) {
    var countDisplay = document.getElementById(textarea.id + "_counter");
    var countDown = false;
    var minCount, maxCount;
    var hasQuill = textarea.getAttribute("data-quill") === 'true';
    var is_disabled = textarea.getAttribute("disabled") === 'disabled';

    if (textarea != null && countDisplay != null) {
      minCount = textarea.getAttribute("data-min-count");
      maxCount = textarea.getAttribute("data-max-count");
    }

    if (hasQuill && !is_disabled) {
      // hide textarea
      textarea.style.display = "none";

      // add editor container
      var quillContainer = document.createElement("div");
      quillContainer.setAttribute("id", textarea.id + "_container");
      quillContainer.setAttribute("class", "w-full bg-white mt-0.5");
      textarea.parentNode.insertBefore(quillContainer, textarea.nextSibling);

      // add editor div
      var quillEditor = document.createElement("div");
      quillEditor.setAttribute("id", textarea.id + "_editor");
      quillContainer.appendChild(quillEditor);

      // load data from textarea
      quillEditor.innerHTML = textarea.value;

      var quill = new Quill(quillEditor, {
        placeholder: 'Hier Text eingeben ...',
        modules: {
          toolbar: TOOLBAR_CONFIG
        },
        theme: 'snow'
      });

      quill.on('text-change', function (delta, oldDelta, source) {
        if (source === 'user') {
          Countable.count(quill.root.innerHTML, updateCharatersLeft, { stripTags: true });
          textarea.value = quill.root.innerHTML;
        }
      });

      Countable.count(quill.root.innerHTML, updateCharatersLeft, { stripTags: true });

    } else {
      Countable.on(textarea, updateCharatersLeft);
    }

    function updateCharatersLeft(counter) {
      var count = counter["all"];
      var left = maxCount - count;

      var currentDisplay = countDisplay.getElementsByClassName("text-count-current")[0];
      var maxDisplay = countDisplay.getElementsByClassName("text-count-max")[0];
      var overDisplay = countDisplay.getElementsByClassName("text-count-overmax")[0];

      if (left < 0) {
        currentDisplay.innerHTML = 0;
        overDisplay.style.display = "inline";
        maxDisplay.innerHTML = -left;
      } else {
        currentDisplay.innerHTML = left;
        overDisplay.style.display = "none";
      }
    }
  }

  function SimpleQuill(textarea) {
    textarea.style.display = "none";

    // add editor container
    var quillContainer = document.createElement("div");
    quillContainer.setAttribute("id", textarea.id + "_container");
    quillContainer.setAttribute("class", "w-full bg-white mt-0.5");
    textarea.parentNode.insertBefore(quillContainer, textarea.nextSibling);

    // add editor div
    var quillEditor = document.createElement("div");
    quillEditor.setAttribute("id", textarea.id + "_editor");
    quillContainer.appendChild(quillEditor);

    // load data from textarea
    quillEditor.innerHTML = textarea.value;

    var quill = new Quill(quillEditor, {
      placeholder: 'Hier Text eingeben ...',
      modules: {
        toolbar: SIMPLE_TOOLBAR_CONFIG
      },
      theme: 'snow'
    });

    quill.on('text-change', function (delta, oldDelta, source) {
      if (source === 'user') {
        textarea.value = quill.root.innerHTML;
      }
    });
  }


  document.addEventListener('DOMContentLoaded', function (e) {
    ;
    [].forEach.call(document.querySelectorAll('[data-count]'), CountableField);
    [].forEach.call(document.querySelectorAll('[data-simple-quill]'), SimpleQuill);
  })


  var barOptions_stacked = {
    tooltips: {
      enabled: false
    },
    hover: {
      animationDuration: 0
    },
    indexAxis: 'y',
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
          fontFamily: "'Open Sans Bold', sans-serif",
          fontSize: 11,
        },
        scaleLabel: {
          display: true
        },
        gridLines: {},
        stacked: true
      },
      y: {
        gridLines: {
          display: false,
          color: "#fff",
          zeroLineColor: "#fff",
          zeroLineWidth: 0
        },
        ticks: {
          fontFamily: "'Open Sans Bold', sans-serif",
          fontSize: 12,
        },
        stacked: true
      }
    },
    legend: {
      display: false
    },
    pointLabelFontFamily: "Quadon Extra Bold",
    scaleFontFamily: "Quadon Extra Bold",
    plugins: {
      datalabels: {
        font: {
          weight: 'bold'
        },
        display: function(context) {
          // skip first dataset if value is max in all datasets (to avoid double label)
          if (context.datasetIndex === 0) {
            // get max at same index in all datasets
            let max = context.chart.data.datasets.map(function(e) {
              return e.data[context.dataIndex];
            }).reduce(function(a, b) {
              return Math.max(a, b);
            });
            return context.dataset.data[context.dataIndex] !== max;
          } else {
            return true;
          }
        },
        formatter: function(value, context) {
          // show total for last dataset
          if (context.datasetIndex === context.chart.data.datasets.length - 1) {
            let total = context.chart.data.datasets.map(function(e) {
               return e.data[context.dataIndex];
            }
            ).reduce(function(a, b) {
              return a + b;
            }
            );
            return total
          } else {
            return value;
          }
        },
      },
    }
  };

  Alpine.data("chart", (datasource) => ({
    chart: null,
    labels: null,
    datasets: null,
    init() {
      let data = JSON.parse(document.getElementById(datasource).textContent);
      this.labels = data.labels;
      this.datasets = data.datasets;
      this.chart = new Chart(this.$el, {
        type: "bar",
        plugins: [ChartDataLabels],
        data: {
          labels: this.labels,
          datasets: this.datasets
        },
        options: barOptions_stacked
      });
    }
  }));

});


Alpine.start();
